.AllPage {
    display: flex;
    justify-content: center;
}

.Page {
    width: 100%;
    max-width: 1440px;
}

.custom-social-btn {
    background-color: #2c2f33;
    color: white;
    border-radius: 30px;
    padding: 10px 20px;
    font-size: 16px;
    transition: background-color 0.3s, transform 0.2s;
    display: flex;
    align-items: center;
    justify-content: start;
    gap: 10px;
    border: none;
}

.custom-social-btn:hover {
    background-color: #40444b;
    transform: translateY(-2px);
}

.custom-social-btn:active {
    background-color: #323436;
    transform: translateY(-2px);
}

.cardHome {
    position: relative;
    width: 300px;
    height: 300px;
    overflow: hidden;
}

.cardHome Img {
    width: 300px;
    height: 300px;
}

.imgHomeHover2 {
    width: 300px;
    height: 300px;
    transition: all linear 0.5s;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
}

.cardHome:hover img {
    opacity: 0;
}

.cardHome:hover img.imgHomeHover2 {
    opacity: 1;
}

.nameOffer {
    color: white;
    position: absolute;
    z-index: 2;
    top: 40vh;
    height: 100px;
    width: 100%;
    /* justify-content: center;
    align-items: center; */
    text-align: center;
    animation: movslider 7s infinite;
}

.BrandShoppBrand {
    font-size: 20px;
    height: 35px;
    transition: border 0.5s;
    padding: 0 20px;
    border: 1px solid white;
    border-radius: 10px;
    color: initial;
    text-decoration: none;
}

.BrandShoppBrand:hover {
    border: 1px solid;
    color: #6f24d7;
}

.ImgModal {
    max-width: 100%;
    min-width: 100%;
    max-height: 15vh;
    min-height: 15vh;
    padding: 5px;
    transition: 0.3s;
}

.ImgModal:hover {
    transform: scale(1.1);
}


.BtnFollowusonInstagram {
    background-color: initial;
    border: 2px solid white;
    color: white;
    border-radius: 10px;
    padding: 5px 10px;
    transition: 0.3s;
}

.BtnFollowusonInstagram:hover {
    background-color: hsla(331, 99%, 50%, 0.5);
    border: 2px solid hsla(331, 99%, 50%, 0.5);
}

@keyframes fade {
    0% {
        opacity: 0;
    }

    20% {
        opacity: 1;
    }

    80% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}







@keyframes movslider {
    0% {
        transform: translateY(100%);
        /* يبدأ من خارج الشاشة من جهة اليمين */
    }

    15% {
        transform: translateY(-50%);
        /* الكلمة في الوسط */
    }

    25% {
        transform: translateY(0);
        /* الكلمة في الوسط */
    }

    50% {
        transform: translateY(0);
        /* الكلمة في الوسط */
    }

    100% {
        transform: translateY(0);
        /* الكلمة تبقى في الوسط */
    }
}