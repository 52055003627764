.AccuntDropdownButton {
    color: black;
    background-color: white;
    border: none;
}

.AccuntDropdownButton:hover {
    color: #6f24d7;
}

.custom-dropdown-button {
    border: none;
    box-shadow: none;
    background: transparent;
    width: 40px;
}

.custom-dropdown-button.dropdown-toggle[aria-expanded="true"] {
    color: black;
}

.custom-dropdown-button::after {
    display: none !important;
}