.slider-container {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 80%;
    margin: auto;
    overflow: hidden;
    /* background: #f9f9f9; */
    /* border: 1px solid #ddd; */
    border-radius: 8px;
    padding: 10px;
}

.slider {
    display: flex;
    gap: 10px;
    transition: transform 0.3s ease-in-out;
    width: 100%;
}

.slider-item {
    flex: 0 0 calc(100% / 3 - 10px);
    text-align: center;
    /* background: #fff; */
    border: 1px solid #ddd;
    border-radius: 4px;
    padding: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.arrow {
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
    padding: 10px;
    color: #333;
}

.arrow:disabled {
    cursor: not-allowed;
    color: #ccc;
}

.prev {
    position: absolute;
    left: -40px;
}

.next {
    position: absolute;
    right: -40px;
}