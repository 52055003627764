.footer-animation-enter {
    opacity: 0;
    transform: translateY(100%);
}



.footer-animation-enter-active {
    opacity: 1;
    transform: translateY(0);
    transition: opacity 600ms, transform 300ms;
}

.footer-animation-exit {
    opacity: 1;
    transform: translateY(0);
}

.footer-animation-exit-active {
    opacity: 0;
    transform: translateY(100%);
    transition: opacity 600ms, transform 300ms;
}

.accordion-button {
    justify-content: space-between;
}


.accordion-button::after {
    margin: 0 !important;
}