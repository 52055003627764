.AccuntDropdownButton {
    color: black;
    background-color: white;
    border: none;
}

.AccuntDropdownButton:hover {
    color: #6f24d7;
}

.offer-banner {
    transition: opacity 0.7s ease, transform 0.7s ease;
    /* التأثير الانتقالي */
    opacity: 1;
    /* العنصر مرئي */
    transform: translateY(0);
    /* العنصر في مكانه الأصلي */
}

.offer-banner.hidden {
    opacity: 0;
    /* إخفاء العنصر */
    transform: translateY(-20px);
    /* تحريكه لأعلى */
}