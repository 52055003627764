.NavHedar {
    width: 100%;
    min-height: 65px;
    background-color: white;
    position: fixed;
    z-index: 5;
    box-shadow: rgb(163, 162, 162) -18px -20px 15px 15px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.NavLinkHedar {
    color: black;
    text-decoration: none;
    font-weight: 700;
    /* margin: 0 10px; */
    position: relative;
    transition: color 0.5s;
}

.MenuNavLink .active {
    color: #842cff;
    border-bottom: 2px #842cff solid;
}

.NavLinkHedar::after {
    content: '';
    position: absolute;
    width: 0;
    height: 2px;
    bottom: -2px;
    left: 0;
    background-color: #842cff;
    transition: width 0.5s;
}

.NavLinkHedar:hover::after {
    width: 100%;
}

.NavLinkHedar:hover {
    color: #842cff;
}

.IconHedar {
    transition: 0.4s;
}

.IconHedar:hover {
    transform: scale(1.05);
}

.CloseBtn {
    display: flex;
    justify-content: space-between;
}

.CloseBtn .btn-close {
    margin: 0;
}

.offer-banner {
    transition: opacity 0.7s ease, transform 0.7s ease;
    /* التأثير الانتقالي */
    opacity: 1;
    /* العنصر مرئي */
    transform: translateY(0);
    /* العنصر في مكانه الأصلي */
}

.offer-banner.hidden {
    opacity: 0;
    /* إخفاء العنصر */
    transform: translateY(-20px);
    /* تحريكه لأعلى */
}


/* .BasicExample .collapsed{
    margin: 0;
} */

/* @media (prefers-reduced-motion: no-preference) {
    .NavLinkHedar {
        animation: hoverNavLink forwards 0.2s linear;
    }
} */


@keyframes hoverNavLink {
    from {
        transform: scale(1);
    }

    to {
        transform: scale(1.07);
    }
}