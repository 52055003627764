* {
  margin: 0;
  padding: 0;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}


.BtnBNB {
  background-color: #842cff;
  border: none;
  color: white;
  border-radius: 7px;
  padding: 0 20px;
  height: 40px;
  bottom: 0;
  transition: 0.3s;
}

.BtnBNB:hover {
  background-color: #6f24d7;
  transition: 0.3s;
}

.BtnBNB:active {
  background-color: #7c1eff;
}

.BtnBNB:disabled {
  background-color: #d3d3d3;
  color: white;
  cursor: not-allowed;
  border: 1px solid #d3d3d3;
}

.BtnBNBIcon {
  background-color: white;
  border: none;
  transition: 0.5s;
}

.BtnBNBIcon:hover {
  color: #6f24d7;
}

.BtnBNBIcon:active {
  color: #7c1eff;
}

.BtnBNBIconFavorite {
  /* background-color: initial; */
  background-color: rgb(255, 255, 255, 1);
  border-radius: 50%;
  padding: 2px;
  color: #6f24d7;
  border: none;
  transition: 0.5s;
}

.BtnBNBIconFavorite:hover {
  color: #a15fff;
}